import { useCallback, useEffect, useRef } from 'react';

import { useNavigate } from '@remix-run/react';

import { authMiddleWare } from '../utils/auth';
import axios from '../utils/axios';
import { useAccessLevel } from './use-access-level';
import { useToken } from './use-token';
import type { AxiosRequestConfig } from 'axios';

export const useAuthedRequest = (noRedirect?: boolean, forcedToken?: string) => {
  const { token, tokenLoading } = useToken();
  const myPromise = useRef<any>();
  const navigate = useNavigate();
  const { accessLevel } = useAccessLevel();

  useEffect(() => {
    if (!noRedirect) {
      authMiddleWare(forcedToken || token, navigate);
    }

    axios.defaults.headers.common = { ...axios.defaults.headers.common, Authorization: `${forcedToken || token}` };
  }, [noRedirect, navigate, accessLevel, token, forcedToken]);

  useEffect(() => {
    if (!tokenLoading && myPromise.current) {
      myPromise.current();
    }
  }, [tokenLoading]);

  const myGet = useCallback(
    async (url: string, config?: AxiosRequestConfig<any> | undefined) => {
      if (tokenLoading) {
        await new Promise(function (resolve, reject) {
          myPromise.current = resolve;
        });
      }
      return axios.get(url, config);
    },
    [tokenLoading],
  );

  const myPost = useCallback(
    async (url: string, data?: any, config?: AxiosRequestConfig<any> | undefined) => {
      if (tokenLoading) {
        await new Promise(function (resolve, reject) {
          myPromise.current = resolve;
        });
      }
      return axios.post(url, data, config);
    },
    [tokenLoading],
  );

  const myPatch = useCallback(
    async (url: string, data?: any, config?: AxiosRequestConfig<any> | undefined) => {
      if (tokenLoading) {
        await new Promise(function (resolve, reject) {
          myPromise.current = resolve;
        });
      }
      return axios.patch(url, data, config);
    },
    [tokenLoading],
  );

  const myDelete = useCallback(
    async (url: string, config?: AxiosRequestConfig<any> | undefined) => {
      if (tokenLoading) {
        await new Promise(function (resolve, reject) {
          myPromise.current = resolve;
        });
      }
      return axios.delete(url, config);
    },
    [tokenLoading],
  );

  return { get: myGet, post: myPost, patch: myPatch, delete: myDelete };
};
