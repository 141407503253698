import { baseURL } from './constants';
import axios from 'axios';

axios.defaults.baseURL = baseURL;

axios.defaults.withCredentials = true;

axios.defaults.headers.common = { 'X-TIMEZONE': Intl.DateTimeFormat().resolvedOptions().timeZone };

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error?.response?.status === 401 &&
      ![
        '/users/login',
        '/users/forgot-password',
        '/users/logout',
        '/users/signup',
        '/users/reset-password',
        '/users/is-auth',
      ].includes(error.request.path) &&
      !error.request.path?.includes('/users/confirm/')
    ) {
      //return (window.location.href = '/logout');
    }
    return Promise.reject(error);
  },
);

export default axios;
