export const baseURL =
  process.env.NODE_ENV === 'development' ? 'http://localhost:5001' : 'https://api.dodgeballhub.com';

export const contactUrlPlaceholder = {
  facebook: 'YOUR_PAGE',
  instagram: 'YOUR_PAGE',
  whatsapp: 'WhatsApp Number',
  other: 'URL or email where to contact you',
  email: 'email@example.com',
  phone: '+1 555-555-5555',
  website: 'example.com',
};
export const typeToLabel = {
  clinic: 'Clinic',
  combine: 'Combine/Tryout',
  practice: 'Practice',
  tournament: 'Tournament',
  league: 'League',
  'drop-in': 'Drop In',
};

export const typeColor = {
  clinic: '#d1a580',
  combine: '#f0bab9',
  practice: '#fce680',
  tournament: '#80cde8',
  league: '#d1debc',
  'drop-in': '#b9b4fd',
  current: '#939799',
};

export const GAME_BUFFER = 15;

export const ADDRESS_STORAGE = 'dodgeballhub::stored::address';
export const DEFAULT_VIEW_STORAGE = 'dodgeballhub::stored::defaultView';
export const MOBILE_LIST_HEIGHT_STORAGE = 'dodgeballhub::stored::mobileListHeight';

export const playerErrorsMap = {
  role: 'Please select a role.',
  birthdate: 'Please select a birthdate.',
  location: 'Please enter a location.',
  number: 'Please enter a jersey number.',
};
