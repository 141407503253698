import type { PropsWithChildren } from 'react';
import React, { createContext, useState } from 'react';

import { useToken } from '~/hooks/use-token';

import { parseJwt } from '../utils/parse-jwt';
import noop from 'lodash/noop';

// Create Context Object
export const AccessLevelContext = createContext<{
  accessLevel?: string;
  updateAccessLevel: () => void;
}>({ updateAccessLevel: noop });

// Create a provider for components to consume and subscribe to changes
export const AccessLevelProvider = ({ children }: PropsWithChildren<{}>) => {
  const { token } = useToken();

  const updateAccessLevel = () => {
    if (!token) {
      return undefined;
    }

    const { accessLevel } = parseJwt(token) || {};

    return accessLevel;
  };
  const [accessLevel, setAccessLevel] = useState<string | undefined>(updateAccessLevel);

  return (
    <AccessLevelContext.Provider
      value={{
        accessLevel,
        updateAccessLevel: () => setAccessLevel(updateAccessLevel()),
      }}
    >
      {children}
    </AccessLevelContext.Provider>
  );
};
